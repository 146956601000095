import axios from "axios";

const API = axios.create({
   // baseURL: "http://localhost:8000/api",
   //baseURL: "https://www.contacttab.com/api",
  //baseURL: "https://contacttab.bbstechsolutions.com/",
  baseURL: "https://app.contacttab.com/api",
});

export default API;

