import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Helmet } from 'react-helmet-async';
import * as yup from "yup";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  styled,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const CreateContact = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  console.log(userid, username, "-------------------------")
  const [contactOwners, setContactOwners] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [contactImage, setContactImage] = useState(null);
  const initialValues = {
    contactOwner: userid || "",
    leadSource: "",
    firstName: "",
    lastName: "",
    accountName: "",
    title: "",
    email: "",
    department: "",
    phone: "",
    homePhone: "",
    otherPhone: "",
    fax: "",
    mobile: "",
    dateOfBirth: "",
    assistant: "",
    asstPhone: "",
    emailOptOut: false,
    skypeId: "",
    secondaryEmail: "",
    twitter: "",
    reportingTo: "",
    mailingStreet: "",
    otherStreet: "",
    mailingCity: "",
    otherCity: "",
    mailingState: "",
    otherState: "",
    mailingZip: "",
    otherZip: "",
    mailingCountry: "",
    otherCountry: "",
    description: "",
    companyCodes: companycode || "", // Initialize company code
  }
  console.log(initialValues)
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || ""); // New state for selected company code
  const [companyCodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const fetchDatas = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchData(); // Fetch data on component mount
    setLeadSources(["Web", "Phone", "Email", "Other"]);
  }, [userrole, companycode, userid, username]);
  // Watch for changes in selectedCompanyCode and fetch data accordingly
  useEffect(() => {
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [selectedCompanyCode]);
  const validationSchema = yup.object({
    contactOwner: yup.string().required("Contact Owner is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        formDataToSend.append("contactImage", contactImage);
        console.log(JSON.stringify(values));
        const response = await axios.post("/addcontact", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("contact data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setContactImage(selectedFiles);
      handleChange({
        target: {
          name: "contactImage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Contact - Contacttab.com</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h1>Create Contact</h1>

          <h4>Contact Image</h4>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
           <input
                        type="file"
                        accept="image/*"
                        id="lead-image-upload"
                        name="contactImage"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
          <label htmlFor="lead-image-upload">
            <Button
              variant="contained"
              component="span"
            >
              Upload Image
            </Button>
          </label>
          {contactImage && (
            <Box sx={{ ml: 2 }}>
              {/* <img src={contactImage} alt="lead" style={{ width: 100, height: 100, objectFit: 'cover' }} /> */}
              {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
            </Box>
          )}
        </Box>

          <h4 style={{ margin: '20px 0px' }}>Contact Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel  htmlFor="contactOwner" style={{ marginBottom: '4px' }}>
                  Contact Owner
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="contactOwner"
                    options={contactOwners}
                    getOptionLabel={(option) => option.firstname || ""}
                    value={contactOwners.find(owner => owner.userid === values.contactOwner) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "contactOwner",
                          value: newValue ? newValue.userid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.contactOwner && Boolean(errors.contactOwner)}
                        helperText={touched.contactOwner && errors.contactOwner}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="leadSource" style={{ marginBottom: '4px' }}>
                  Lead Source
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CustomSelect
                    name="leadSource"
                    value={values.leadSource}
                    onChange={handleChange}
                  >
                    {leadSources.map((source) => (
                      <MenuItem key={source} value={source}>{source}</MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="firstName" style={{ marginBottom: '4px' }}>
                  First Name
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>

              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="lastName" style={{ marginBottom: '4px' }}>
                  Last Name
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="accountName" style={{ marginBottom: '4px' }}>
                  Account Name
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="accountName"
                  value={values.accountName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="title" style={{ marginBottom: '4px' }}>
                  Title
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="email" style={{ marginBottom: '4px' }}>
                  Email
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="department" style={{ marginBottom: '4px' }}>
                  Department
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="department"
                  value={values.department}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Phone Number Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="phone" style={{ marginBottom: '4px' }}>
                  Phone No
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              {/* Company Code Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="companyCodes" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
              {userrole === "Superadmin" && companycode === 1 ? (
                <Grid item xs={6} sm={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={companyCodes}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setSelectedCompanyCode(value || ""); // Update selected company code
                        handleChange({
                          target: {
                            name: "companyCodes",
                            value: value || "", // Ensure it handles the case where no value is selected
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.companyCodes && !!errors.companyCodes}
                          helperText={touched.companyCodes && errors.companyCodes ? errors.companyCodes : ""}
                        />
                      )}
                      value={values.companyCodes} // Controlled value
                      isOptionEqualToValue={(option, value) => option === value} // Check equality
                    />

                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={6} sm={4}>
                  <TextField
                    fullWidth
                    type="text"
                    value={companycode}
                    name="companyCodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} alignItems="center">
              {/* Home Phone Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="homePhone" style={{ marginBottom: '4px' }}>
                  Home Phone
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="homePhone"
                  value={values.homePhone}
                  onChange={handleChange}
                />
              </Grid>

              {/* Other Phone Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherPhone" style={{ marginBottom: '4px' }}>
                  Other Phone
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherPhone"
                  value={values.otherPhone}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Fax Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                  Fax
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="fax"
                  value={values.fax}
                  onChange={handleChange}
                />
              </Grid>

              {/* Mobile Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mobile" style={{ marginBottom: '4px' }}>
                  Mobile
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                />
              </Grid></Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Date of Birth Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="dateOfBirth" style={{ marginBottom: '4px' }}>
                  Date of Birth
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="dateOfBirth"
                  type="date"
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Assistant Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="assistant" style={{ marginBottom: '4px' }}>
                  Assistant
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="assistant"
                  value={values.assistant}
                  onChange={handleChange}
                />
              </Grid></Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Assistant Phone Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="asstPhone" style={{ marginBottom: '4px' }}>
                  Assistant Phone
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="asstPhone"
                  value={values.asstPhone}
                  onChange={handleChange}
                />
              </Grid>

              {/* Skype ID Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="skypeId" style={{ marginBottom: '4px' }}>
                  Skype ID
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="skypeId"
                  value={values.skypeId}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Secondary Email Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="secondaryEmail" style={{ marginBottom: '4px' }}>
                  Secondary Email
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="secondaryEmail"
                  value={values.secondaryEmail}
                  onChange={handleChange}
                />
              </Grid>

              {/* Twitter Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="twitter" style={{ marginBottom: '4px' }}>
                  Twitter
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="twitter"
                  value={values.twitter}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Reporting To Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="reportingTo" style={{ marginBottom: '4px' }}>
                  Reporting To
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="reportingTo"
                  value={values.reportingTo}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

          </Grid>

          <h4 style={{ margin: '20px 20px' }}>Address Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingStreet" style={{ marginBottom: '4px' }}>
                  Mailing Street
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingStreet"

                  value={values.mailingStreet}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherStreet" style={{ marginBottom: '4px' }}>
                  Other Street
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherStreet"

                  value={values.otherStreet}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingCity" style={{ marginBottom: '4px' }}>
                  Mailing City
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingCity"

                  value={values.mailingCity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherCity" style={{ marginBottom: '4px' }}>
                  Other City
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherCity"

                  value={values.otherCity}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingState" style={{ marginBottom: '4px' }}>
                  Mailing State
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingState"

                  value={values.mailingState}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherState" style={{ marginBottom: '4px' }}>
                  Other State
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherState"

                  value={values.otherState}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingZip" style={{ marginBottom: '4px' }}>
                  Mailing Zip
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingZip"

                  value={values.mailingZip}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherZip" style={{ marginBottom: '4px' }}>
                  Other Zip
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherZip"

                  value={values.otherZip}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingCountry" style={{ marginBottom: '4px' }}>
                  Mailing Country
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingCountry"

                  value={values.mailingCountry}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherCountry" style={{ marginBottom: '4px' }}>
                  Other Country
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherCountry"

                  value={values.otherCountry}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <h4 style={{ margin: '20px 20px' }}>Additional Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
                  Description
                </InputLabel>
              </Grid>
              <Grid item xs={10}>
                <CustomTextField
                  fullWidth
                  name="description"

                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Box mt={2} display="flex" justifyContent="center">
            <Button type="button" onClick={() => resetForm()} variant="contained" color="primary" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }} >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
              Save
            </Button>
          </Box> */}
            <Box display="flex" justifyContent="center" mt="20px" mb="50px">
            <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button>
            <Button type="button" onClick={() => resetForm()} variant="contained" color="primary" style={{ marginLeft: '20px',background: 'Red'}} >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default CreateContact;
