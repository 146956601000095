import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../utils/axios";
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import { useUser } from "../../contexts/auth";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CreateAccount = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const [companyCodes, setCompanyCodes] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || "");
  const [accountOwners, setAccountOwners] = useState([]);
  const initialValues = {
    accountOwner: userid || "",
    accountName: "",
    accountSite: "",
    parentAccount: "",
    accountNumber: "",
    accountType: "",
    industry: "",
    annualRevenue: "",
    rating: "",
    phone: "",
    fax: "",
    website: "",
    tickerSymbol: "",
    ownership: "",
    employees: "",
    sicCode: "",
    billingStreet: "",
    billingCity: "",
    billingState: "",
    billingCode: "",
    billingCountry: "",
    shippingStreet: "",
    shippingCity: "",
    shippingState: "",
    shippingCode: "",
    shippingCountry: "",
    description: "",
    companyCodes: companycode || "", // Initialize company code
  }
  const [accountImage, setAccountImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const validationSchema = yup.object({
    accountName: yup.string().required("Account Name is required"),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        formDataToSend.append("accountImage", accountImage);
        console.log(JSON.stringify(values));
        const response = await axios.post("/addaccount", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("Account data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const fetchDatas = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccountOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  useEffect(() => {
    fetchDatas();
    fetchData(); // Fetch data on component mount
  }, [userrole, companycode, userid, username,]);
  useEffect(() => {
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [selectedCompanyCode]);
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAccountImage(selectedFiles);
      handleChange({
        target: {
          name: "accountimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setAccountImage(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Create Account - Contacttab.com</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h1>Create Account</h1>

          <h4>Account Image</h4>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <input
              accept="image/*"
              id="account-image-upload"
              name="accountimage"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <label htmlFor="account-image-upload">
              <Button
                variant="contained"
                component="span"
              >
                Upload Image
              </Button>
            </label>
            {accountImage && (
              <Box sx={{ ml: 2 }}>
                {/* <img src={accountImage} alt="Account" style={{ width: 100, height: 100, objectFit: 'cover' }} /> */}
                {selectedFileNamePan && (
                  <div>Selected file: {selectedFileNamePan}</div>
                )}
              </Box>
            )}
          </Box>

          <h4 style={{ margin: '20px 0px' }}>Account Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="accountOwner" style={{ marginBottom: '4px' }}>
              Account Owner
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <Autocomplete
                    id="accountOwner"
                    options={accountOwners}
                    getOptionLabel={(option) => option.firstname || ""}
                    value={accountOwners.find(owner => owner.userid === values.accountOwner) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "accountOwner",
                          value: newValue ? newValue.userid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.accountOwner && Boolean(errors.accountOwner)}
                        helperText={touched.accountOwner && errors.accountOwner}
                      />
                    )}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="rating" style={{ marginBottom: '4px' }}>
              Rating
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <CustomSelect
                  name="rating"
                  value={values.rating}
                  onChange={handleChange}
                >
                  <MenuItem value="-None-">-None-</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="companyCodes" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
              {userrole === "Superadmin" && companycode === 1 ? (
                <Grid item xs={6} sm={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={companyCodes}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setSelectedCompanyCode(value || ""); // Update selected company code
                        handleChange({
                          target: {
                            name: "companyCodes",
                            value: value || "", // Ensure it handles the case where no value is selected
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.companyCodes && !!errors.companyCodes}
                          helperText={touched.companyCodes && errors.companyCodes ? errors.companyCodes : ""}
                        />
                      )}
                      value={values.companyCodes} // Controlled value
                      isOptionEqualToValue={(option, value) => option === value} // Check equality
                    />

                  </FormControl>
                </Grid>
              ) : (
              <Grid item xs={6} sm={4}>
                <TextField
                  fullWidth
                  type="text"
                  value={companycode}
                  name="companyCodes"
                  sx={{ gridColumn: "span 2" }}
                />
              </Grid>
            )}
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="accountName" style={{ marginBottom: '4px' }}>
              Account Name
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="accountName"
                value={values.accountName}
                onChange={handleChange}
                error={touched.accountName && Boolean(errors.accountName)}
                helperText={touched.accountName && errors.accountName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="phone" style={{ marginBottom: '4px' }}>
                Phone
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="phone"
                value={values.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="accountSite" style={{ marginBottom: '4px' }}>
                Account Site
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="accountSite"
                value={values.accountSite}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                Fax
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="fax"
                value={values.fax}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="parentAccount" style={{ marginBottom: '4px' }}>
               Parent Account
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="parentAccount"
                value={values.parentAccount}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="website" style={{ marginBottom: '4px' }}>
              Website
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="website"
                value={values.website}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="accountNumber" style={{ marginBottom: '4px' }}>
              Account Number
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="accountNumber"
                value={values.accountNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="tickerSymbol" style={{ marginBottom: '4px' }}>
              Ticker Symbol
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="tickerSymbol"
                value={values.tickerSymbol}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="accountType" style={{ marginBottom: '4px' }}>
              Account Type
              </InputLabel>
            </Grid>

            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <CustomSelect
                  name="accountType"
                  value={values.accountType}
                  onChange={handleChange}
                >
                  <MenuItem value="-None-">-None-</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="ownership" style={{ marginBottom: '4px' }}>
              Ownership
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <CustomSelect
                  name="ownership"
                  value={values.ownership}
                  onChange={handleChange}
                >
                  <MenuItem value="-None-">-None-</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="industry" style={{ marginBottom: '4px' }}>
              Industry
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <CustomSelect
                  name="industry"
                  value={values.industry}
                  onChange={handleChange}
                >
                  <MenuItem value="-None-">-None-</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="employees" style={{ marginBottom: '4px' }}>
              Employees
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="employees"
                value={values.employees}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={1.6}>
              <InputLabel htmlFor="annualRevenue" style={{ marginBottom: '4px' }}>
              Annual Revenue
              </InputLabel>
            </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="annualRevenue"
                value={values.annualRevenue}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="sicCode" style={{ marginBottom: '4px' }}>
                SIC Code
                </InputLabel>
              </Grid>
          <Grid item xs={6} sm={4}>
            <CustomTextField
              fullWidth
              name="sicCode"
              value={values.sicCode}
              onChange={handleChange}
            />
          </Grid>
          </Grid>
          </Grid>

          <h4 style={{ margin: '20px 0px' }}>Address Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="billingStreet" style={{ marginBottom: '4px' }}>
                Billing Street
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="billingStreet"
                value={values.billingStreet}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="shippingStreet" style={{ marginBottom: '4px' }}>
                Shipping Street
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="shippingStreet"
                value={values.shippingStreet}
                onChange={handleChange}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="billingCity"style={{ marginBottom: '4px' }}>
                Billing City
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="billingCity"
                value={values.billingCity}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="shippingCity" style={{ marginBottom: '4px' }}>
                 Shipping City
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="shippingCity"
                value={values.shippingCity}
                onChange={handleChange}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="billingState" style={{ marginBottom: '4px' }}>
                Billing State
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="billingState"
                value={values.billingState}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="shippingState" style={{ marginBottom: '4px' }}>
                Shipping State
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="shippingState"
                value={values.shippingState}
                onChange={handleChange}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="billingCode" style={{ marginBottom: '4px' }}>
                Billing Code
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="billingCode"
                value={values.billingCode}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="shippingCode" style={{ marginBottom: '4px' }}>
                Shipping Code
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="shippingCode"
                value={values.shippingCode}
                onChange={handleChange}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="billingCountry" style={{ marginBottom: '4px' }}>
                Billing Country
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="billingCountry"
                value={values.billingCountry}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="shippingCountry" style={{ marginBottom: '4px' }}>
                Shipping Country
                </InputLabel>
              </Grid>
            <Grid item xs={6} sm={4}>
              <CustomTextField
                fullWidth
                name="shippingCountry"
                value={values.shippingCountry}
                onChange={handleChange}
              />
            </Grid>
            </Grid>
          </Grid>

          <h4 style={{ margin: '20px 0px' }}>Description Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
                Description
                </InputLabel>
              </Grid>
            <Grid item xs={10}>
              <CustomTextField
                fullWidth
                name="description"
                multiline
                rows={4}
                value={values.description}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="center">
            {/* <Button type="button" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save and New
          </Button> */}
            <Button type="submit" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
              Save
            </Button>
            <Button type="button" onClick={() => resetForm()} variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default CreateAccount;