import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Helmet } from 'react-helmet-async';
import { useUser } from "../../contexts/auth";
import axios from "../../utils/axios";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Autocomplete,
  Snackbar,
  Tooltip,
} from "@mui/material";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CreateDeal = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const initialValues = {
    dealOwner: userid,
    dealName: "",
    accountName: "",
    amount: "",
    closingDate: "",
    stage: "Qualification",
    type: "-None-",
    probability: "10",
    nextStep: "",
    expectedRevenue: "",
    leadSource: "-None-",
    campaignSource: "",
    contactName: "",
    description: "",
    companyCodes: companycode || "", // Initialize company code
  }
  const validationSchema = yup.object({
    dealName: yup.string().required("Deal Name is required"),
  });
  const [accountOwners, setAccountOwners] = useState([]);
  const [accountnames, setAccountnames] = useState([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || "");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [companyCodes, setCompanyCodes] = useState([]);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        //formDataToSend.append("accountImage", accountImage);
        console.log(JSON.stringify(values));
        const response = await axios.post("/adddeal", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("contact data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const fetchData = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccountOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchAccount = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getaccounts", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccountnames(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchDatas = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
    fetchDatas();
    fetchAccount();
  }, [userrole, companycode, userid, username,]);
  useEffect(() => {
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [selectedCompanyCode]);
  return (
    <>
      <Helmet>
        <title>Create Deal - Contacttab.com</title>
      </Helmet>

      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h1>Create Deal</h1>

          <h4>Deal Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Deal Owner" arrow>
                  <InputLabel htmlFor="dealOwner" style={{ marginBottom: '4px' }}>
                    Deal Owner
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Autocomplete
                  id="dealOwner"
                  options={accountOwners}
                  getOptionLabel={(option) => option.firstname || ""}
                  value={accountOwners.find(owner => owner.userid === values.dealOwner) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "dealOwner",
                        value: newValue ? newValue.userid : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={touched.dealOwner && Boolean(errors.dealOwner)}
                      helperText={touched.dealOwner && errors.dealOwner}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Amount" arrow>
                  <InputLabel htmlFor="amount" style={{ marginBottom: '4px' }}>
                    Amount
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="amount"
                  type="text"
                  value={values.amount}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Deal Name" arrow>
                  <InputLabel htmlFor="dealName" style={{ marginBottom: '4px' }}>
                    Deal Name
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="dealName"
                  value={values.dealName}
                  onChange={handleChange}
                  error={touched.dealName && Boolean(errors.dealName)}
                  helperText={touched.dealName && errors.dealName}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Closing Date" arrow>
                  <InputLabel htmlFor="Closing Date" style={{ marginBottom: '4px' }}>
                    Closing Date
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="closingDate"
                  type="date"
                  value={values.closingDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Account Name" arrow>
                  <InputLabel htmlFor="accountName" style={{ marginBottom: '4px' }}>
                    Account Name
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
              <Autocomplete
                  id="accountName"
                  options={accountnames}
                  getOptionLabel={(option) => option.accountname || ""}
                  value={accountnames.find(owner => owner.accountname === values.accountName) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "accountName",
                        value: newValue ? newValue.accountname : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={touched.accountName && Boolean(errors.accountName)}
                      helperText={touched.accountName && errors.accountName}
                    />
                  )}
                />
                {/* <CustomTextField
                  fullWidth
                  name="accountName"
                  value={values.accountName}
                  onChange={handleChange}
                /> */}
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Stage" arrow>
                  <InputLabel htmlFor="stage" style={{ marginBottom: '4px' }}>
                    Stage
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CustomSelect
                    name="stage"
                    value={values.stage}
                    onChange={handleChange}
                  >
                    <MenuItem value="Qualification">Qualification</MenuItem>
                    <MenuItem value="Needs Analysis">Needs Analysis</MenuItem>
                    {/* <MenuItem value="Analysis">Analysis</MenuItem>
                <MenuItem value="Needs">Needs</MenuItem> */}
                    {/* Add other stages as needed */}
                  </CustomSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Type" arrow>
                  <InputLabel htmlFor="type" style={{ marginBottom: '4px' }}>
                    Type
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CustomSelect
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    <MenuItem value="-None-">-None-</MenuItem>
                    {/* Add other types as needed */}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Probability" arrow>
                  <InputLabel htmlFor="probability" style={{ marginBottom: '4px' }}>
                    Probability
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="probability"
                  value={values.probability}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Next Step" arrow>
                  <InputLabel htmlFor="nextStep" style={{ marginBottom: '4px' }}>
                    Next Step
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="nextStep"
                  value={values.nextStep}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Expected Revenue" arrow>
                  <InputLabel htmlFor="expectedRevenue" style={{ marginBottom: '4px' }}>
                    Expected Revenue
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="expectedRevenue"
                  value={values.expectedRevenue}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: '$',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Lead Source" arrow>
                  <InputLabel htmlFor="leadSource" style={{ marginBottom: '4px' }}>
                    Lead Source
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CustomSelect
                    name="leadSource"
                    value={values.leadSource}
                    onChange={handleChange}
                  >
                    <MenuItem value="-None-">-None-</MenuItem>
                    {/* Add other lead sources as needed */}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Campaign Source" arrow>
                  <InputLabel htmlFor="campaignSource" style={{ marginBottom: '4px' }}>
                    Campaign Source
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="campaignSource"
                  value={values.campaignSource}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Company Code" arrow>
                  <InputLabel htmlFor="companyCodes" style={{ marginBottom: '4px' }}>
                    Company Code
                  </InputLabel>
                </Tooltip>
              </Grid>
              {userrole === "Superadmin" && companycode === 1 ? (
                <Grid item xs={6} sm={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={companyCodes}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setSelectedCompanyCode(value || ""); // Update selected company code
                        handleChange({
                          target: {
                            name: "companyCodes",
                            value: value || "", // Ensure it handles the case where no value is selected
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.companyCodes && !!errors.companyCodes}
                          helperText={touched.companyCodes && errors.companyCodes ? errors.companyCodes : ""}
                        />
                      )}
                      value={values.companyCodes} // Controlled value
                      isOptionEqualToValue={(option, value) => option === value} // Check equality
                    />

                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={6} sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    value={companycode}
                    name="companyCodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
              )}
              <Grid item xs={4} sm={1.6}>
                <Tooltip title="Contact Name" arrow>
                  <InputLabel htmlFor="contactName" style={{ marginBottom: '4px' }}>
                    Contact Name
                  </InputLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="contactName"
                  value={values.contactName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <h4>Description Information</h4>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={1.6}>
              <Tooltip title="Description" arrow>
                <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
                  Description
                </InputLabel>
              </Tooltip>
            </Grid>
            <Grid item xs={10}>
              <CustomTextField
                fullWidth
                name="description"
                multiline
                rows={4}
                value={values.description}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="center">
            {/* <Button type="button" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save and New
          </Button> */}
            <Button type="submit" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
              Save
            </Button>
            <Button type="button" onClick={() => resetForm()} variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default CreateDeal;