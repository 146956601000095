import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Helmet } from 'react-helmet-async';
import * as yup from "yup";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  styled,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import API from "../../utils/axios";
import { useUser } from "../../contexts/auth";
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const UpdateContact= () => {
    const {contactid } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  console.log(userid, username, "-------------------------")
  const [contactOwners, setContactOwners] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [contactImage, setContactImage] = useState(null);
  const initialValues = {
    contactowner: "",
    leadsource: "",
    firstname: "",
    lastname: "",
    accountname: "",
    title: "",
    email: "",
    department: "",
    phone: "",
    homephone: "",
    otherphone: "",
    fax: "",
    mobile: "",
    dateOfbirth: "",
    assistant: "",
    asstPhone: "",
    emailoptout: false,
    skypeid: "",
    secondaryemail: "",
    twitter: "",
    reportingto: "",
    mailingstreet: "",
    otherstreet: "",
    mailingcity: "",
    othercity: "",
    mailingstate: "",
    otherstate: "",
    mailingzip: "",
    otherzip: "",
    mailingcountry: "",
    othercountry: "",
    description: "",
    companycodes: companycode || "", // Initialize company code
  }
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || ""); // New state for selected company code
  const [companycodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const fetchDatas = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await axios.get(`/getcontactbyid/${contactid}`);
       console.log(response.data)
       const contactdata = response.data;
        if (response && response.data) {
          setValues({
            ...contactdata,
          });
          console.log(response.data)
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    };
    fetchLeadData();
  }, [contactid]);
  const fetchData = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchData(); // Fetch data on component mount
    setLeadSources(["Web", "Phone", "Email", "Other"]);
  }, [userrole, companycode, userid, username]);
  // Watch for changes in selectedCompanyCode and fetch data accordingly
  useEffect(() => {
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [selectedCompanyCode]);
  const validationSchema = yup.object({
    contactowner: yup.string().required("Contact Owner is required"),
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
  });
  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companycodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        formDataToSend.append("contactimage", contactImage);
        console.log(JSON.stringify(values));
        const response = await axios.post(`/updatecontact/${contactid}`, formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("contact data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setContactImage(selectedFiles);
      handleChange({
        target: {
          name: "contactimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Contact - Contacttab.com</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h1>Create Contact</h1>

          <h4>Contact Image</h4>
          <a href={`${API.defaults.baseURL}/uploadimage/contact/${values.contactimg}`} target="_blank" rel="noopener noreferrer"> 
          <span>{values.contactimg}</span></a>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
           <input
                        type="file"
                        accept="image/*"
                        id="lead-image-upload"
                        name="contactImage"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
          <label htmlFor="lead-image-upload">
            <Button
              variant="contained"
              component="span"
            >
              Upload Image
            </Button>
          </label>
          {contactImage && (
            <Box sx={{ ml: 2 }}>
              {/* <img src={contactImage} alt="lead" style={{ width: 100, height: 100, objectFit: 'cover' }} /> */}
              {selectedFileNamePan && (
                        <div>Selected file: {selectedFileNamePan}</div>
                      )}
            </Box>
          )}
        </Box>

          <h4 style={{ margin: '20px 0px' }}>Contact Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="contactowner" style={{ marginBottom: '4px' }}>
                  Contact Owner
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="contactowner"
                    options={contactOwners}
                    getOptionLabel={(option) => option.firstname || ""}
                    value={contactOwners.find(owner => owner.userid === values.contactowner) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "contactowner",
                          value: newValue ? newValue.userid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.contactowner && Boolean(errors.contactowner)}
                        helperText={touched.contactowner && errors.contactowner}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="leadsource" style={{ marginBottom: '4px' }}>
                  Lead Source
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CustomSelect
                    name="leadsource"
                    value={values.leadsource}
                    onChange={handleChange}
                  >
                    {leadSources.map((source) => (
                      <MenuItem key={source} value={source}>{source}</MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="firstname" style={{ marginBottom: '4px' }}>
                  First Name
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="lastname" style={{ marginBottom: '4px' }}>
                  Last Name
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  id="lastname"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="accountname" style={{ marginBottom: '4px' }}>
                  Account Name
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="accountname"
                  value={values.accountname}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="title" style={{ marginBottom: '4px' }}>
                  Title
                </InputLabel>
              </Grid>

              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="email" style={{ marginBottom: '4px' }}>
                  Email
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="department" style={{ marginBottom: '4px' }}>
                  Department
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="department"
                  value={values.department}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Phone Number Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="phone" style={{ marginBottom: '4px' }}>
                  Phone No
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              {/* Company Code Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="companycodes" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
              {userrole === "Superadmin" && companycode === 1 ? (
                <Grid item xs={6} sm={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={companycodes}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setSelectedCompanyCode(value ||values.companycodes); // Update selected company code
                        handleChange({
                          target: {
                            name: "companycodes",
                            value: value || values.companycodes, // Ensure it handles the case where no value is selected
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.companycodes && !!errors.companycodes}
                          helperText={touched.companycodes && errors.companycodes ? errors.companycodes : ""}
                        />
                      )}
                      value={values.companycodes} // Controlled value
                      isOptionEqualToValue={(option, value) => option === value} // Check equality
                    />

                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={6} sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    value={values.companycode}
                    name="companycodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} alignItems="center">
              {/* Home Phone Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="homephone" style={{ marginBottom: '4px' }}>
                  Home Phone
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="homephone"
                  value={values.homephone}
                  onChange={handleChange}
                />
              </Grid>

              {/* Other Phone Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherphone" style={{ marginBottom: '4px' }}>
                  Other Phone
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherphone"
                  value={values.otherphone}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Fax Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                  Fax
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="fax"
                  value={values.fax}
                  onChange={handleChange}
                />
              </Grid>

              {/* Mobile Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mobile" style={{ marginBottom: '4px' }}>
                  Mobile
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                />
              </Grid></Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Date of Birth Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="dateOfbirth" style={{ marginBottom: '4px' }}>
                  Date of Birth
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="dateOfbirth"
                  type="date"
                  value={values.dateOfbirth}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Assistant Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="assistant" style={{ marginBottom: '4px' }}>
                  Assistant
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="assistant"
                  value={values.assistant}
                  onChange={handleChange}
                />
              </Grid></Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Assistant Phone Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="asstPhone" style={{ marginBottom: '4px' }}>
                  Assistant Phone
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="asstPhone"
                  value={values.asstPhone}
                  onChange={handleChange}
                />
              </Grid>

              {/* Skype ID Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="skypeid" style={{ marginBottom: '4px' }}>
                  Skype ID
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="skypeid"
                  value={values.skypeid}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Secondary Email Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="secondaryemail" style={{ marginBottom: '4px' }}>
                  Secondary Email
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="secondaryemail"
                  value={values.secondaryemail}
                  onChange={handleChange}
                />
              </Grid>

              {/* Twitter Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="twitter" style={{ marginBottom: '4px' }}>
                  Twitter
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="twitter"
                  value={values.twitter}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Reporting To Field */}
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="reportingto" style={{ marginBottom: '4px' }}>
                  Reporting To
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="reportingto"
                  value={values.reportingto}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

          </Grid>

          <h4 style={{ margin: '20px 20px' }}>Address Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingstreet" style={{ marginBottom: '4px' }}>
                  Mailing Street
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingstreet"

                  value={values.mailingstreet}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherstreet" style={{ marginBottom: '4px' }}>
                  Other Street
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherstreet"

                  value={values.otherstreet}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingcity" style={{ marginBottom: '4px' }}>
                  Mailing City
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingcity"

                  value={values.mailingcity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="othercity" style={{ marginBottom: '4px' }}>
                  Other City
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="othercity"

                  value={values.othercity}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingstate" style={{ marginBottom: '4px' }}>
                  Mailing State
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingstate"

                  value={values.mailingstate}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherstate" style={{ marginBottom: '4px' }}>
                  Other State
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherstate"

                  value={values.otherstate}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingzip" style={{ marginBottom: '4px' }}>
                  Mailing Zip
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingzip"

                  value={values.mailingzip}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="otherzip" style={{ marginBottom: '4px' }}>
                  Other Zip
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="otherzip"

                  value={values.otherzip}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="mailingcountry" style={{ marginBottom: '4px' }}>
                  Mailing Country
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="mailingcountry"

                  value={values.mailingcountry}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="othercountry" style={{ marginBottom: '4px' }}>
                  Other Country
                </InputLabel>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CustomTextField
                  fullWidth
                  name="othercountry"

                  value={values.othercountry}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <h4 style={{ margin: '20px 20px' }}>Additional Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} sm={1.6}>
                <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
                  Description
                </InputLabel>
              </Grid>
              <Grid item xs={10}>
                <CustomTextField
                  fullWidth
                  name="description"

                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Box mt={2} display="flex" justifyContent="center">
            <Button type="button" onClick={() => resetForm()} variant="contained" color="primary" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }} >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
              Save
            </Button>
          </Box> */}
            <Box display="flex" justifyContent="center" mt="20px" mb="50px">
            <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button>
            <Button type="button" onClick={() => resetForm()} variant="contained" color="primary" style={{ marginLeft: '20px',background: 'Red'}} >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default UpdateContact;
