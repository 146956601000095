import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Helmet } from 'react-helmet-async';
import { useUser } from "../../contexts/auth";
import axios from "../../utils/axios";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import { useParams } from 'react-router-dom';
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const ModifyDeal = () => {
  const { dealid } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const initialValues = {
    dealowner: userid ,
    dealname: "",
    accountname: "",
    amount: "",
    closingdate: "",
    stage: "Qualification",
    type: "-None-",
    probability: "10",
    nextstep: "",
    expectedrevenue: "",
    leadsource: "-None-",
    campaignsource: "",
    contactname: "",
    description: "",
    companycode: "" // Initialize company code
  }
  const validationSchema = yup.object({
    dealname: yup.string().required("Deal Name is required"),
  });
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleReset,
      handleSubmit,
      resetForm,
      setValues,
    } = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: async () => {
        try {
          if (userrole !== "Superadmin" || companycode !== 1) {
            values.companycode= companycode;
          }
          const leadDataWithUsername = {
            ...values,
            username: username,
          };
          const formDataToSend = new FormData();
  
          formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
          //formDataToSend.append("accountImage", accountImage);
          console.log(JSON.stringify(values));
          const response = await axios.post(`/modifydeal/${dealid}`, formDataToSend);
          if (response.status === 200) {
            // Update state with success message
            setSuccessMessage("contact data added successful");
            setOpenSuccess(true);
            // Hide the success message after 3 seconds (adjust duration as needed)
            setTimeout(() => {
              setOpenSuccess(false);
              setSuccessMessage("");
            }, 3000);
          }
        } catch (error) {
          console.error("Error submitting form:", error);
  
          // Handle specific error cases
          if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
          }
        }
      },
    });
  const [accountOwners, setAccountOwners] = useState([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(values.companycode || "");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [companyCodes, setCompanyCodes] = useState([]);
  const fetchData = async () => {
    try {
      if (values.companycode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: values.companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccountOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchDealdatas = async () => {
    try {
      const response = await axios.get(`getdealbyid/${dealid}`);
      console.log(response.data);
      setValues(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
//   const fetchDatas = async () => {
    const fetchDatas = async () => {
        try {
          const response = await axios.get("/getCompanyRegistrations");
          console.log(response.data);
          const formattedCodes = response.data.map(
            (company) => company.establishmentid
          );
          setCompanyCodes(formattedCodes);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
    fetchDatas();
    fetchDealdatas();
  }, [userrole, companycode, userid, username,values.companycode]);
//   useEffect(() => {
//     if (selectedCompanyCode) {
//       fetchData(); // Fetch data when selectedCompanyCode changes
//     }
//   }, [selectedCompanyCode]);
  return (
    <>
    <Helmet>
      <title>Create Deal - Contacttab.com</title>
    </Helmet>

    <form onSubmit={handleSubmit}>
      <Box m="20px">
        <h1>Create Deal</h1>

        <h4>Deal Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={1.6}>
              <InputLabel htmlFor="dealowner" style={{ marginBottom: '4px' }}>
               Deal Owner
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
            <Autocomplete
                    id="dealowner"
                    options={accountOwners}
                    getOptionLabel={(option) => option.firstname || ""}
                    value={accountOwners.find(owner => owner.userid === values.dealowner) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "dealowner",
                          value: newValue ? newValue.userid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.dealowner && Boolean(errors.dealowner)}
                        helperText={touched.dealowner && errors.dealowner}
                      />
                    )}
                  />
          </Grid>
          <Grid item xs={1.6}>
              <InputLabel htmlFor="amount" style={{ marginBottom: '4px' }}>
              Amount
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="amount"
              type="text"
              value={values.amount}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="dealname" style={{ marginBottom: '4px' }}>
               Deal Name
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="dealname"
              value={values.dealname}
              onChange={handleChange}
              error={touched.dealname && Boolean(errors.dealname)}
              helperText={touched.dealname && errors.dealname}
            />
          </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="Closing Date" style={{ marginBottom: '4px' }}>
               Closing Date
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="closingdate"
              type="date"
              value={values.closingdate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="accountname" style={{ marginBottom: '4px' }}>
              Account Name
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="accountname"
              value={values.accountname}
              onChange={handleChange}
            />
          </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="stage" style={{ marginBottom: '4px' }}>
               Stage
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <CustomSelect
                name="stage"
                value={values.stage}
                onChange={handleChange}
              >
                <MenuItem value="Qualification">Qualification</MenuItem>
                <MenuItem value="Needs Analysis">Needs Analysis</MenuItem>
                {/* <MenuItem value="Analysis">Analysis</MenuItem>
                <MenuItem value="Needs">Needs</MenuItem> */}
              </CustomSelect>
            </FormControl>
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="type" style={{ marginBottom: '4px' }}>
              Type
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <CustomSelect
                name="type"
                value={values.type}
                onChange={handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
                {/* Add other types as needed */}
              </CustomSelect>
            </FormControl>
          </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="probability" style={{ marginBottom: '4px' }}>
              Probability
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="probability"
              value={values.probability}
              onChange={handleChange}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="nextstep" style={{ marginBottom: '4px' }}>
              Next Step
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="nextstep"
              value={values.nextstep}
              onChange={handleChange}
            />
          </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="expectedrevenue" style={{ marginBottom: '4px' }}>
              Expected Revenue
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="expectedrevenue"
              value={values.expectedrevenue}
              onChange={handleChange}
              InputProps={{
                startAdornment: '$',
              }}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadsource" style={{ marginBottom: '4px' }}>
              Lead Source
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <CustomSelect
                name="leadsource"
                value={values.leadsource}
                onChange={handleChange}
              >
                <MenuItem value="-None-">-None-</MenuItem>
                {/* Add other lead sources as needed */}
              </CustomSelect>
            </FormControl>
          </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="campaignsource" style={{ marginBottom: '4px' }}>
              Campaign Source
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="campaignsource"
              value={values.campaignsource}
              onChange={handleChange}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={1.6}>
                <InputLabel htmlFor="companyCode" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    value={values.companycode}
                    name="companycode"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="contactname" style={{ marginBottom: '4px' }}>
              Contact Name
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="contactname"
              value={values.contactname}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        </Grid>
        <h4>Description Information</h4>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
              Description
              </InputLabel>
            </Grid>
          <Grid item xs={10}>
            <CustomTextField
              fullWidth
              name="description"
              multiline
              rows={4}
              value={values.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" justifyContent="center">
          {/* <Button type="button" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save and New
          </Button> */}
          <Button type="submit" variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Save
          </Button>
          <Button type="button" onClick={() => resetForm()} variant="contained" style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
    <br />
    <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default ModifyDeal;